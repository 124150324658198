import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { composeClasses, isNotEmptyArray } from 'utils'

import * as styles from 'styles/customDivider.module.css'

const CustomDivider = ({ data, children, path }) => {
  const defaultData = Object.keys(data)[0]
  const [selectedData, setSelectedData] = useState(data[defaultData])
  const leftColumnList = Object.keys(data)

  const handleDataChange = (item, currentTarget) => {
    const leftColumnDiv = currentTarget && currentTarget.parentNode.parentNode
    const rightColumnDiv = leftColumnDiv && leftColumnDiv.nextElementSibling

    if ((path && data[item].slug) && window.getComputedStyle(rightColumnDiv).display === 'none') {
      window.location.href = `${path}/${data[item].slug}`
    } else {
      setSelectedData(data[item])
    }
  }

  return (
    <div className={styles.divider}>
      <div className={styles.leftColumn}>
        <ul className={styles.list}>
          {isNotEmptyArray(leftColumnList) && leftColumnList.map((item, index) => (
            <li
              className={composeClasses(
                styles.listItem,
                item === selectedData.key && styles.activeClass
              )}
              key={index.toString()}
              onClick={(e) => handleDataChange(item, e.currentTarget)}
            >
              <strong className={styles.textHolder}>{item}</strong>
              <span className={styles.logoHolder}>
                {data[item] && data[item].logo &&
                  <img
                    alt={data[item] && data[item].logo.altText}
                    height='25'
                    src={data[item] && data[item].logo.sourceUrl}
                    width='78'
                  />
                }
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.rightColumn}>
        {typeof children === 'function' ? children(selectedData) : children}
      </div>
    </div>
  )
}

CustomDivider.defaultProps = {
  path: null
}

CustomDivider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  data: PropTypes.object.isRequired,
  path: PropTypes.string
}

export default CustomDivider
