import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import CustomDivider from 'components/customDivider'
import { composeClasses, formatHTMLEntity } from 'utils'

import * as ClientStyles from '../styles/clients.module.css'
import * as ServiceStyles from '../styles/services.module.css'

export const query = graphql`
  query($first: Int) {
    wordPress {
      clients(first: $first) {
        edges {
          node {
            slug
            status
            clientsFieldGroup {
              clientName
              clientLogo {
                sourceUrl
                altText
              }
              clientIndustry
              clientWebsiteUrl
              clientShortTestimonial
              clientFullTestimonial
              clientProjectOverview
            }
          }
        }
      }
      pages(where: { name: "Clients" }) {
        edges {
          node {
            headerSection {
              heroHeading
              heroText
            }
          }
        }
      }
    }
  }
`

const ClientsPage = ({ data }) => {
  const clientData = data.wordPress.clients.edges.reduce((acc, val) => {
    const key = val.node.clientsFieldGroup.clientName
    const data = {
      [key]: {
        logo: val.node.clientsFieldGroup.clientLogo,
        slug: val.node.slug,
        ...val.node.clientsFieldGroup,
        key
      }
    }
    return { ...acc, ...data }
  }, {})

  return (
    <>
      <SEO
        description='We work across highly regulated industries, in verticals such as
        eCommerce, FinTech and FMCG.'
        title='Clients' />
      <div>
        <div>
          <div>
            <div>
              <h1>
                {data.wordPress.pages.edges[0].node.headerSection.heroHeading}
              </h1>
            </div>
          </div>
          <div className={ClientStyles.subHeading}>
            <div className={ClientStyles.subHeadingContent}>
              <p>{data.wordPress.pages.edges[0].node.headerSection.heroText}</p>
            </div>
          </div>
          <div className={ServiceStyles.spacer} />
          <div>
            <div className={ClientStyles.clientFooterContent}>
              <h5>Clients:</h5>
            </div>
            <div className={ClientStyles.clientFooterContent}>
              <h5>Testimonial:</h5>
            </div>
          </div>
        </div>
        <CustomDivider
          data={clientData}
          path='/client'>
          {selected => {
            return (
              <div>
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        selected.clientFullTestimonial
                    }}
                  />
                  <Link to={'/client/' + selected.slug}>
                    <p
                      className={composeClasses(
                        ServiceStyles.blueColor,
                        ServiceStyles.bottomNavigation
                      )}
                    >
                      {formatHTMLEntity('View Full Project', '279D')}
                    </p>
                  </Link>
                  <div
                    className={composeClasses(
                      ServiceStyles.flex,
                      ServiceStyles.clientDetails
                    )}
                  >
                    <div className={ServiceStyles.client}>
                      <div className={ServiceStyles.innerSectionHeader}>
                        <h5>Client Logo:</h5>
                      </div>
                      {selected.clientLogo && (
                        <img
                          alt={
                            selected.clientLogo.altText
                          }
                          className={ClientStyles.clientLogo}
                          src={
                            selected.clientLogo.sourceUrl
                          }
                        />
                      )}
                    </div>
                  </div>
                  <Link to={'/client/' + selected.slug}>
                    <h1
                      className={composeClasses(
                        ServiceStyles.blueColor,
                        ServiceStyles.bottomNavigation
                      )}
                    >
                      {formatHTMLEntity('Full Project', '276F')}
                    </h1>
                  </Link>
                </div>
              </div>
            )
          }}
        </CustomDivider>
      </div>
    </>
  )
}
ClientsPage.propTypes = {
  data: PropTypes.object.isRequired
}
export default ClientsPage
